import React from 'react';
import Mail from '../../resources/images/mail';
import Telegram from '../../resources/images/telegram';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div class="footer__left">© Copyright EzSignals. All Rights Reserved.</div>
        <ul className="footer__right">
          <li>
            <Mail /> <a href="mailto: support@ezforex.app">support@ezforex.app</a>
          </li>
          <li>
            <Telegram /> <a href="https://t.me/ezforexapp">ezsignalsapp</a>
          </li>
          <li>Term of Sevices</li>
          <li>Privacy Policy</li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
