import React, { useRef, useState, useLayoutEffect, useEffect, useMemo, useCallback, Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { renderNavigation } from './navigationLink';
import { useLocation } from 'react-router-dom';
import LogoHeader from '../../resources/images/logo.png';
import DrawerMenu from '../Drawer';
import MenuTop from '../MenuTop';
import MenuOutlined from '../../resources/images/menu_outline.svg';
import { Events } from 'react-scroll';
import { browserHistory } from '../../utils/history';
import { throttle } from '../../utils/index';
import { Link as ScrollLink } from 'react-scroll';
import LightThemeIcon from '../../resources/images/light_theme.svg';
import DarkThemeIcon from '../../resources/images/dark_theme.svg';
import { ThemeContext } from 'hooks/useDarkMode';
import SelectComponent from '../Select';
import FlagCN from '../../resources/images/china.svg';
import FlagEN from '../../resources/images/english.svg';
import FlagJP from '../../resources/images/japan.svg';
import FlagKR from '../../resources/images/korea.svg';
import { getCurrentLanguage, resources } from '../../i18n/i18n';
import { Language } from 'common/constant';
import { Button } from 'antd';

function Header({ isMobile }) {
  const { t, i18n } = useTranslation();
  const headerRef = useRef(null);
  const languageSelect = useRef(null);
  const [hash, setHash] = useState('home');
  const [defaultLanguage, setDefaultLanguage] = useState(Language.EN);
  const { theme, setTheme } = useContext(ThemeContext);

  const [isAllowSetHash, setIsAllowSetHash] = useState(true);

  const navigation = useMemo(() => renderNavigation(t), [t]);

  const location = useLocation();

  const getCurrentPage = useCallback(() => {
    return navigation.reduce((cur, acc) => {
      if (acc.children.length) {
        const currentSelectedChild = acc.children.find((child) => location.pathname === child.link);
        return currentSelectedChild && currentSelectedChild.key ? { cur, ...currentSelectedChild } : { ...cur };
      } else {
        return location.pathname === acc.link ? { cur, ...acc } : { ...cur };
      }
    }, {});
  }, [navigation, location.pathname]);

  const [currentPage, setCurrentPage] = useState(getCurrentPage ? getCurrentPage.key : navigation[0].key);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    handleChange(getCurrentLanguage());

    Events.scrollEvent.register('begin', () => {
      setHash('');
      setIsAllowSetHash(false);
    });
    Events.scrollEvent.register('end', () => {
      setIsAllowSetHash(true);
    });
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  useEffect(() => {
    setCurrentPage(getCurrentPage ? getCurrentPage.key : navigation[0].key);
  }, [getCurrentPage, navigation, location.pathname]);

  useEffect(() => {
    if (isDrawerOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isDrawerOpen]);

  const setActiveSection = useCallback(() => {
    if (!isMobile) {
      const allSection = Array.from(
        document.querySelectorAll('#banner, #about, #how_it_works, #testimonials', '#product')
      );
      const scrollPosition = document.documentElement.scrollTop;
      const windowOffset = window.pageYOffset;

      if (allSection.length) {
        for (let i = 0; i < allSection.length - 1; i++) {
          if (
            scrollPosition > windowOffset + allSection[i].getBoundingClientRect().top - 500 &&
            scrollPosition < windowOffset + allSection[i + 1].getBoundingClientRect().top - 500
          ) {
            isAllowSetHash && setHash(allSection[i].id);
            break;
          }

          if (scrollPosition > windowOffset + allSection[allSection.length - 1].getBoundingClientRect().top - 500) {
            isAllowSetHash && setHash(allSection[allSection.length - 1].id);
            break;
          }
        }
      }
    }
  }, [isAllowSetHash, isMobile]);

  useLayoutEffect(() => {
    scrollFunc();
    setActiveSection();
    window.addEventListener('scroll', scrollFunc);
    window.addEventListener('scroll', throttle(400, setActiveSection));

    return () => {
      window.removeEventListener('scroll', scrollFunc);
      window.removeEventListener('scroll', setActiveSection);
    };
  }, [setActiveSection]);

  const handleRedirectHomePage = useCallback((hash) => {
    if (!document.getElementById(hash)) {
      browserHistory.push('/');
      setHash(hash);
    }

    setIsDrawerOpen(false);
    setHash(hash);
  }, []);

  const handleClickNavigation = useCallback((e) => {
    setCurrentPage(e.key);
  }, []);

  const scrollFunc = function () {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      headerRef.current.classList.add('tiny-header');
    } else {
      headerRef.current.classList.remove('tiny-header');
    }
  };

  const toggleDrawer = () => {
    setIsDrawerOpen((prevIsDrawerOpen) => !prevIsDrawerOpen);
  };

  const changeTheme = useCallback(() => {
    // if (theme !== "dark") {
    //   setTheme("theme", "dark");
    // } else {
    //   setTheme("theme", "light");
    // }
    setTheme('theme', 'dark');
  }, [theme, setTheme]);

  const handleChange = (value) => {
    let language = value;
    if (Object.keys(resources).indexOf(value) === -1) {
      language = Language.EN;
    }

    if (value.indexOf('zh') >= 0) {
      language = Language.CN;
    }

    i18n.changeLanguage(language);
    setDefaultLanguage(options.filter((lang) => lang.key === language)[0]);
  };

  const options = useMemo(() => {
    return [
      {
        key: 'en',
        value: (
          <Fragment>
            <img className="flag" alt="English" src={FlagEN} />
            <span>{t('common.english')}</span>
          </Fragment>
        ),
      },
      // {
      //   key: 'ja',
      //   value: (
      //     <Fragment>
      //       <img className='flag' alt='Japan' src={FlagJP} />
      //       <span>{t('common.japan')}</span>
      //     </Fragment>
      //   )
      // },
      // {
      //   key: 'ko',
      //   value: (
      //     <Fragment>
      //       <img className='flag' alt='Korean' src={FlagKR} />
      //       <span>{t('common.korea')}</span>
      //     </Fragment>
      //   )
      // },
      // {
      //   key: 'cn',
      //   value: (
      //     <Fragment>
      //       <img className='flag' alt='Chinese' src={FlagCN} />
      //       <span>{t('common.china')}</span>
      //     </Fragment>
      //   )
      // }
    ];
  }, []);

  // const sortedOptions = useMemo(() => {
  //   const selectedLanguage = options.filter(
  //     (option) => option.key === defaultLanguage.key
  //   );
  //   const restLanguaguages = options.filter(
  //     (option) => option.key !== defaultLanguage.key
  //   );

  //   return [...selectedLanguage, ...restLanguaguages];
  // }, [defaultLanguage, options]);

  const themeToggle = useMemo(() => {
    return (
      <Fragment>
        {/* <div className="theme__toggle" onClick={changeTheme}>
          {theme === "dark" ? (
            <Fragment>
              <img
                width={18}
                height={18}
                src={LightThemeIcon}
                alt="Light theme"
              />
            </Fragment>
          ) : (
            <Fragment>
              <img
                width={18}
                height={18}
                src={DarkThemeIcon}
                alt="Dark theme"
              />
            </Fragment>
          )}
        </div> */}
        <Button className="button-primary">Try for free</Button>
      </Fragment>
    );
  }, [theme, changeTheme, defaultLanguage, options]);

  return (
    <div id="header" className="header" ref={headerRef}>
      <div className="container">
        <div className="logo">
          <ScrollLink
            to={'home'}
            onClick={() => handleRedirectHomePage('home')}
            smooth={true}
            duration={500}
            offset={-110}
          >
            <img src={LogoHeader} alt="EzSignals" />
          </ScrollLink>
        </div>
        {!isMobile && (
          <MenuTop
            handleRedirectHomePage={handleRedirectHomePage}
            handleClick={handleClickNavigation}
            current={currentPage}
            navigation={navigation}
            mode="horizontal"
            activeElement={hash}
            isAllowSetHash={isAllowSetHash}
            themeToggle={themeToggle}
          />
        )}
        {isMobile && (
          <div className="header-mobile">
            {themeToggle}
            <img width={24} height={24} alt="menu" src={MenuOutlined} onClick={toggleDrawer} />

            <DrawerMenu
              handleRedirectHomePage={handleRedirectHomePage}
              isMobile={isMobile}
              visible={isDrawerOpen}
              handleClick={handleClickNavigation}
              toggleDrawer={toggleDrawer}
              current={currentPage}
              navigation={[
                ...navigation,
                {
                  key: 'termOfServices',
                  text: 'Terms of Services',
                  link: '',
                  isHash: false,
                  children: [],
                },
                {
                  key: 'privacyPolicy',
                  text: 'Privacy Policy',
                  link: '',
                  isHash: false,
                  children: [],
                },
              ]}
              onClose={toggleDrawer}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
