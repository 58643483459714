import React from 'react';
import { Row, Typography, Col } from 'antd';
import IconMarketScanner from '../../resources/images/icon_market_scanner.svg';
import IconEasyToUse from '../../resources/images/icon_easy_to_use.svg';
import IconDiverseMarket from '../../resources/images/icon_diverse_market.svg';
import IconOnlineSupport from '../../resources/images/icon_online_support.svg';
import IconRealtime from '../../resources/images/icon_realtime.svg';
import IconSaveTime from '../../resources/images/icon_save_time.svg';
import IconIndicatorAlert from '../../resources/images/icon_indicator_alert.svg';

const { Title, Paragraph } = Typography;

const About = () => {
  const data = [
    {
      icon: IconMarketScanner,
      title: 'Market Scanner',
      description:
        'Track market movements: Bullish or bearish base on indicator/strategy: RSI, MACD, Bollinger Band, Ichimoku, Price Action, …',
    },
    {
      icon: IconIndicatorAlert,
      title: 'Indicator alert',
      description: 'Set trading alerts any time based on indicators for trading pairs: XAU/USD, USOIL, US30',
    },
    {
      icon: IconRealtime,
      title: 'RealTime Push Notifications',
      description:
        'Keep you from missing trading opportunities and Easily make quick decisions to get the most out of your investment.',
    },
    {
      icon: IconDiverseMarket,
      title: 'Diverse market',
      description: 'Integrated diverse markets such as: Forex, Cryptocurrency, Commodity, Stock, Indices.',
    },
    {
      icon: IconEasyToUse,
      title: 'Easy to Use',
      description: 'Only 3 simple steps, you can setup the alert base on the indicator or strategy you want.',
    },
    {
      icon: IconSaveTime,
      title: 'Save time',
      description: 'You can track the entire market even at sleep without having to open the candle chart screen.',
    },
    {
      icon: IconOnlineSupport,
      title: '24/7 Online Support',
      description: 'Support team is always on 24/7 to get ready to help you.',
    },
  ];

  return (
    <section className="section about" id="about">
      <div className="container">
        <Title className="title" level={2}>
          Why it make easy?
        </Title>
        <Paragraph className="description">
          Best daily signals app for all traders
          <br />
          All you need to do is log in to EzSignals and make an order according to signal.
          <br />
          EzSignals saves you time looking at charts and improves trading efficiency.
        </Paragraph>
        <div className="list">
          <Row
            gutter={[
              {
                sm: 60,
                lg: 77,
              },
              40,
            ]}
          >
            {data.map(({ icon, title, description }, index) => (
              <Col xs={24} sm={12} lg={8} key={index}>
                <div className="about-block">
                  <img src={icon} className="about-icon" alt={title} />
                  <div className="about-title">{title}</div>
                  <div className="about-description">{description}</div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </section>
  );
};

export default About;
