import React from 'react';
import { Space, Typography } from 'antd';

import GooglePlay from '../../resources/images/google-play.svg';
import AppStore from '../../resources/images/app-store.svg';

const { Title, Paragraph } = Typography;

const Product = () => {
  return (
    <section className="section product" id="product">
      <div className="container">
        <div className="product-box">
          <Title className="title" level={2}>
            Easy trade like pro <span className="text-primary">with EzSignals</span>
          </Title>
          <Paragraph>Get started for free now</Paragraph>
          <Space size={16}>
            <a href="https://play.google.com/store/apps/details?id=com.ezforex">
              <img src={GooglePlay} alt="Google Play" className="image-store" />
            </a>
            <a href="https://apps.apple.com/kg/app/ezforex/id1579226074">
              <img src={AppStore} alt="Google Play" className="image-store" />
            </a>
          </Space>
        </div>
      </div>
    </section>
  );
};

export default Product;
