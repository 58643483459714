export const renderNavigation = (t) => [
  {
    key: 'What’s this',
    text: 'What’s this',
    link: 'about',
    isHash: true,
    children: [],
  },
  {
    key: 'How it works',
    text: 'How it works',
    link: 'how_it_works',
    isHash: true,
    children: [],
  },
  {
    key: 'Reviews',
    text: 'Reviews',
    link: 'testimonials',
    isHash: true,
    children: [],
  },
];
