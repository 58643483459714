import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import Testimonials from 'components/Testimonials';
import Banner from 'components/Banner';
import { BackTop } from 'antd';
import IconArrowUp from '../../resources/images/Icon_up.svg';
import IconArrowUpLight from '../../resources/images/Icon_up_light.svg';
import { ThemeContext } from 'hooks/useDarkMode';
import About from 'components/About';
import HowItWorks from 'components/HowItWorks';
import Product from 'components/Product';

function HomePage() {
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';

  return (
    <div className="home-page">
      <Banner />
      <About />
      <HowItWorks />
      <Testimonials />
      <Product />

      <BackTop>
        <div className="wrapper">
          <img src={isDarkMode ? IconArrowUp : IconArrowUpLight} alt="up" />
        </div>
      </BackTop>
    </div>
  );
}
export default withRouter(HomePage);
