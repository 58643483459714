import React from 'react';
import { Col, Row, Space, Typography } from 'antd';
import GooglePlay from '../../resources/images/google-play.svg';
import AppStore from '../../resources/images/app-store.svg';

const { Title, Paragraph } = Typography;

function HomeSection() {
  return (
    <section className="section banner" id="banner">
      <div className="container">
        <Row>
          <Col md={12}>
            <Title level={1}>
              Make <span className="text-secondary">it</span> easy{' '}
              <span className="text-secondary">for your trade</span> with{' '}
              <span className="text-primary">EzSignals</span>
            </Title>
            <Paragraph>
              Top forex signal selection platform
              <br />
              Start trading forex simple, convenient and effective
              <br />
              Get started for free now
            </Paragraph>
            <Space size={24}>
              <a href="https://play.google.com/store/apps/details?id=com.ezforex">
                <img src={GooglePlay} alt="Google Play" className="image-store" />
              </a>
              <a href="https://apps.apple.com/kg/app/ezforex/id1579226074">
                <img src={AppStore} alt="Google Play" className="image-store" />
              </a>
            </Space>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default HomeSection;
