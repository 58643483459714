import React from 'react';
import { Row, Col } from 'antd';
import { Typography } from 'antd';

import { ourTeam } from './teamMember';

const { Title, Paragraph } = Typography;

function Testimonials() {
  return (
    <section className="section testimonials" id="testimonials">
      <div className="container">
        <Title level={2} className="title">
          Testimonials & Reviews
        </Title>
        <Paragraph className="description">
          EzSignals has helped many people achieve results and assisted many in trading.
          <br />
          Check out their reviews below…
        </Paragraph>
        <Row
          className="card__section"
          gutter={[
            {
              sm: 20,
              lg: 18,
              xl: 32,
            },
            32,
          ]}
        >
          {ourTeam.map(({ name, position, image, review }) => (
            <Col xs={24} md={12} lg={8}>
              <div className="team-card">
                <div className="team-card__top">
                  <img src={image} alt={name} className="team-card__avatar" />
                  <div className="team-card__right">
                    <Title level={5} className="team-card__title">
                      {name}
                    </Title>
                    <div className="team-card__position">{position}</div>
                  </div>
                </div>
                <div className="team-card__review">{review}</div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
}

export default Testimonials;
