import React from 'react';
import { Typography } from 'antd';
import MobileApp from '../../resources/images/mobile-app-preview.png';

const { Title } = Typography;

const HowItWorks = () => {
  return (
    <section className="section how-it-works" id="how_it_works">
      <div className="container">
        <Title className="title" level={2}>
          How it works
        </Title>

        <div className="process">
          <div className="step">
            <div className="value">1</div>
            <div className="label">Download App EzSignals</div>
          </div>
          <div className="line"></div>
          <div className="step">
            <div className="value">2</div>
            <div className="label">Login/Sign up on App EzSignals</div>
          </div>
          <div className="line"></div>
          <div className="step">
            <div className="value">3</div>
            <div className="label">Let’s start trader!!</div>
          </div>
        </div>
        <img src={MobileApp} alt="ExForex Mobile App" className="preview" />
      </div>
    </section>
  );
};

export default HowItWorks;
